import React from "react";
import { graphql } from "gatsby";
import Page from "../../components/Page";
import { GatsbyImage } from "gatsby-plugin-image"

export default function Progge({ data }) {
  const image = data.image.childImageSharp.gatsbyImageData;

  const title = "Tjäna pengar på programmering (Guide)"

  return (
    <>
      <Page title={title} description="Inom området programmering så finns det också många sätt att tjäna pengar på, och man kan exempelvis bygga hemsidor, appar, plugin och mycket mer.">
        <div className="container px-5 py-6 mx-auto">
          <article className="blogPost">
            {image && <GatsbyImage image={image} className="mb-5 rounded-xl" alt="programmera" />}
            <h1 className="font-bold text-4xl md:text-5xl text-gray-900 leading-tight mb-8">{title}</h1>


            <p>Idag så går vi mot en alltmer digital värld, och programmering är något som blir mer och mer viktigt för varje år. Inom området programmering så finns det också många sätt att tjäna pengar på, och man kan exempelvis bygga hemsidor, appar, plugin och mycket mer.</p>
            <p>I detta inlägget kommer jag gå igenom flera sätt hur man kan tjäna pengar på programmering och ta upp flera konkreta exempel. Jag själv har en bakgrund inom datateknik och högpresterande datorsystem och flera av förslagen har jag testat tidigare, och andra planerar jag att framöver. </p>
            <h2>Hur kan man tjäna pengar på programmering?</h2>
            <p>Om man vill tjäna pengar på sina programmeringskunskaper så finns det tre olika vägar att gå. Du kan arbeta med kunder och olika projekt för dem, såsom att utveckla en hemsida eller en app till en kund. Fördelen med detta alternativet är att du vet redan innan vad du kommer få ut från ett projekt, eftersom du skrivit ett avtal med kunden från start. </p>
            <p>Det andra sättet att tjäna pengar på programmering är att utvecklar en tjänst som du sedan säljer. Detta kallas även för SaaS (Software as a Service) och är något som blir allt vanligare för varje år. En sådan tjänst hade kunnat vara att du tillhandahåller ett API som löser flera kunders problem, och där kunderna betalar dig månadsvis. Du bygger alltså en tjänst som du sedan säljer och som genererar dig en passiv inkomst.</p>
            <p>Det tredje alternativet är att du utvecklar tjänster eller produkter såsom en app eller en hemsida och där du sedan genererar pengar på passiva inkomster. De passiva inkomsterna kan exempelvis komma från annonsintäkter och affilatemarknadsföring.</p>
            <p>Min syn på det hela är att alternativ två och tre är de mest effektiva sätten att tjäna pengar på programmering. Varför? Jo, för de har störst skalbarhet och kan generera inkomster flera år framöver parallellt som du kan utveckla och fokusera på andra projekt samtidigt. Nackdelen med dessa två är däremot att de kan innebära en större risk eftersom du inte vet innan vad du kommer få ut för resultat av allt ditt hårda arbete.</p>
            <h2>Hur mycket tjänar man på programmering?</h2>
            <p>Hur mycket pengar du kan tjäna beror på flera faktorer, men huvudfaktorn är vilken källa du får dina inkomster ifrån. Om du utvecklar hemsidor åt kunder på beställning kommer du få aktiva inkomster vilket innebär att du får betalt av det du gör just då. Om du arbetar aktivt kommer det också finnas en övre gräns på vad du kan tjäna eftersom du har begränsat med tid varje månad. Exempelvis kanske du får in 35 000 kr per utvecklad Wordpress-hemsida.</p>
            <p>Om du bygger produkter och tjänster som du sedan säljer, såsom SaaS, finns det däremot ingen gräns på hur mycket pengar du kan tjäna. Anledningen till detta är att det finns en otrolig skalbarhet. Det samma gäller om du utvecklar appar eller hemsidor som du sedan publicerar. De kan generera inkomster flera år framöver. Nackdelen är däremot att du kan utveckla en app och publicera den, men om ingen laddar ner din app kommer du inte tjäna några pengar alls. </p>
            <h2>5 sätt att tjäna pengar på programmering</h2>
            <p>Nu vet vi att det finns många sätt som man kan tjäna pengar på programmering. Här nedan tänkte jag gå igenom 5 olika sätt i mer detalj hur man kan tjäna pengar på programmering.</p>
            <h3>1. Utveckla hemsidor till kunder</h3>
            <p>Jag har en väldigt bra vän som gör detta vid sidan av sina studier. Han utvecklar hemsidor i Wordpress för olika butiker som vill digitalisera sig. Det han gjorde för att få kunder var att gå in till olika butiker och frågade om de hade en hemsida. Om de inte hade det frågade han om hade funderat på att skaffa en och erbjöd sig att utveckla en hemsida för dem.</p>
            <p>Om man skall utveckla en hemsida till en kund skulle jag rekommendera att använda sig av Wordpress. Anledningen till detta är att det går väldigt snabbt att ta fram en hemsida i Wordpress och att det möjliggör för kunden att uppdatera texten på sin hemsida enkelt senare. När du utvecklat en hemsida till en kund kan du dessutom erbjuda dina tjänster att fungera som support och hålla plugin för hemsidan uppdaterad för ett visst pris i månaden.</p>
            <h3>2. Utveckla mobilappar </h3>
            <p>När jag gick på gymnasiet utvecklade jag en hel del mobilappar för Android med hjälp av Android Studio. Detta gav mig senare lite passiva inkomster i form av annonsintäkter varje månad. Om jag däremot hade utvecklat en applikation idag hade jag valt ett ramverk såsom React Native eller Flutter. Anledningen till varför jag valt några av dessa idag är att de gör de möjligt att utveckla en app för flera plattformar såsom Android och iOS samtidigt.</p>
            <p>När du utvecklat din app kan du antingen tjäna pengar på annonsintäkter eller genom att du sätter ett pris på din app. Många appar har dessutom köp inuti appen vilket kan vara ett annat effektivt sätt.</p>
            <h3>3. Bygg egna affilateshemsidor</h3>
            <p>För att utveckla hemsidor behöver du kunna programmeringsspråk såsom HTML, CSS och Javascript. Jag själv testar just nu detta sätt och har än så länge tjänat några kronor även om det inte är några stora belopp. För affilateshemsidor så tjänar du pengar på affilatemarknadsföring vilket innebär att du rekommenderar andra produkter och tjänster med olika länkar. Om en läsare sen klickar på länken och gör en viss handling kommer du att tjäna pengar på det. </p>
            <p>Min upplevelse från affilateshemsidor är att det tar rätt så lång tid att utveckla en bra hemsida som har mycket trafik. Jag ser däremot att det finns en stor potential i det och det är framförallt därför jag gör det just nu. Jag har utvecklat min hemsida i React och använder mig av affilatenätverk såsom Adtraction och Adrecord. För min hosting så använder jag mig av Netlify just nu.</p>
            <h3>4. Utveckla APIer</h3>
            <p>API står för application programming interface och kan jämföras som en bro som tillåter olika program att kommunicera med varandra. APIn underlättar ofta användningen av en viss funktionalitet såsom att hämta och behandla olika data.</p>
            <p>Jag skulle rekommendera att utveckla ditt API i NodeJS och ExpressJS och sedan publicera det på RapidAPI vilket är en plattform för att sälja olika APIer som tjänster. Det som RapidAPI gör är att de håller koll på antalet förfrågningar som alla dina kunder/användare gör. Och de begär sedan en betalning beroende på hur mycket dina kunder använder ditt API. Att utveckla APIer kan vara ett bra sätt att tjäna en liten extra inkomst.</p>
            <h3>5. Bygg plugin för Wordpress</h3>
            <p>Om du byggt en hemsida i Wordpress tidigare så vet du att alla som använder Wordpress använder en mängd av olika plugin till sina sidor. På Wordpress finns det tusentals plugin, där en del är gratis och en del kostar pengar för att uppgradera till en nästa nivå. </p>
            <p>Om du hittar en lösning på ett problem för Wordpress kan du skapa ett plugin för det. För om du har ett problem som du löst så finns det garanterat andra som också letar efter lösningen för samma problem. Om du funderar på att utveckla ett plugin för Wordpress kan du sedan sälja det på CodeCanyon.net.</p>
            <br />
            <br />
          </article>
        </div>
      </Page>
    </>
  );
}

export const query = graphql`
  query {
    image: file(relativePath: { eq: "programmer.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`;
